
import { mixins, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import OverlayPanel from "primevue/overlaypanel";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { capitalize } from "vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import InputAdornment from "@/app/ui/components/input-adornment/index.vue";
import { STTToBeGenerate } from "@/domain/entities/StiDestSc";
import {
  convertDecimal,
  convertDecimalAfterComma,
  convertDecimalWithComma,
  isMigrateFromElexys,
  removeDataLocalStorage,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { StiDestScController } from "@/app/ui/controllers/StiDestScController";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import Print from "@/app/ui/views/incoming-process/sti-dest-sc/component/modules/generate.vue";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import isFormatLiloBag from "@/app/infrastructures/misc/common-library/isFormatLiloBag";

@Options({
  components: {
    DetailLayout,
    OverlayPanel,
    InputDimension,
    InputAdornment,
    Print
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
      this.setPartial(false);
      this.setOpenSuccess(false);
    }
  }
})
export default class CreateFormStiDestSCManifest extends mixins(
  OutGoingMixins
) {
  mounted() {
    AccountController.checkAccountLocation();
  }

  // delete stt pieces
  sttNumberListTrash: Array<string> = [];
  showDeleteSttNumber = false;
  sttNumberToDelete = "";
  sttNumberSuccessDelete = "";
  errorSttNumberDelete = "";
  async onValidateDeleteSttNumber(val: any) {
    const splitVal = val.split("#");
    if (splitVal.length > 0) this.sttNumberToDelete = splitVal[0];
    this.onDeleteSttNumber();
  }
  onDeleteSttNumber() {
    if (this.sttNumberToDelete) {
      this.errorSttNumberDelete = "";
      this.sttNumberSuccessDelete = "";
      this.sttNumberToDelete = this.sttNumberToDelete.toUpperCase();

      // find index no. stt
      const indexSttNumber = this.dataSttToBeUpdate.findIndex((data: any) => {
        return (
          data.sttNumber === this.sttNumberToDelete ||
          data.sttElexysNumber === this.sttNumberToDelete
        );
      });

      // no. stt not found
      if (indexSttNumber < 0) {
        this.errorSttNumberDelete = "No. STT tidak ditemukan/sudah terhapus";
      } else {
        this.dataSttToBeUpdate.splice(indexSttNumber, 1);
        this.sttNumberListTrash.push(this.sttNumberToDelete);
        this.sttNumberSuccessDelete = this.sttNumberToDelete;
        this.sttNumberToDelete = "";
      }
    }
    const deleteStt: any = this.$refs.deleteStt;
    deleteStt.focus();
  }
  onShowDelete() {
    this.errorSttNumberDelete = "";
    this.showDeleteSttNumber = true;
    this.sttNumberToDelete = "";
    this.sttNumberSuccessDelete = "";
  }
  onCloseDelete() {
    this.showDeleteSttNumber = false;
    this.sttNumberListTrash = [];
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  get detailDistrict() {
    return LocationController.districtDetail;
  }
  get typeUser() {
    return capitalize(this.dataProfile.account_type_detail.type);
  }

  // validate stt number
  isMisRoute = false;
  isTransit = false;
  isStiDest = false;
  get sttNumberTypeStatus() {
    if (this.isMisRoute)
      return {
        icon: "warning",
        title: "MIS-ROUTE",
        color: "bg-red-lp-100"
      };
    else if (this.isTransit)
      return {
        icon: "warning-yellow",
        title: "Transit",
        color: "bg-yellow-lp-300"
      };
    else if (this.isStiDest)
      return {
        icon: "image-modal-success",
        title: "STI DEST-SC",
        color: "bg-green-lp-100"
      };
    return false;
  }

  // handle stt number
  isPaid = true;
  isUnpaidNotification = false;
  errorSttNumber = "";
  isValidateScan = false;

  onCloseNotification() {
    this.isUnpaidNotification = false;
    this.notification = false;
  }

  onResetForm() {
    this.isMisRoute = false;
    this.isTransit = false;
    this.isStiDest = false;
    this.form.sttNumber = "";
    this.isErrorValidationScan = false;
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();
  }

  async onValidateSttNumber(val: any) {
    if (!this.isDisableAddSttNumber) {
      this.isValidateScan = true;
      const splitVal = val.split("#");
      if (splitVal.length > 0) this.form.sttNumber = splitVal[0];
      await this.addSttNumber();
    }
  }

  // add stt number
  async addSttNumber() {
    let getTracking = false;
    if (!this.isDisableAddSttNumber) {
      this.onCloseNotification();
      if (!this.isSttNumber(this.form.sttNumber) && !this.isLiloBag(this.form.sttNumber)) {
        getTracking = await TrackingController.trackStt({
          sttNo: this.form.sttNumber,
          isGetSla: false
        });
      }
      const sttTrackingData =
        TrackingController.trackingSttData.sttDetail.sttNo;
      await StiDestScController.getDetailSttStiDestSc({
        sttOrBagNumber: getTracking ? sttTrackingData : this.form.sttNumber,
        callback: this.addStt
      });
    }
  }

  isSttNumber(sttNumber: string) {
    return /^\d{1,2}LP\w+$/g.test(sttNumber);
  }

  isLiloBag(bagNo: string) {
    return isFormatLiloBag(bagNo)
  }

  addStt(detailSTT: any) {
    const checkAllPayment = detailSTT.filter(
      (item: any) => item.isPaid === false
    );
    for (const index in detailSTT) {
      this.addSttNumberToUpdate(detailSTT[index], checkAllPayment.length === 0);
    }
  }

  addSttNumberToUpdate(detailSTT: any, checkAllPayment: any) {
    if (detailSTT.isAllowUpdateStatus && detailSTT.piece !== null) {
      this.onStiDestScData(detailSTT, checkAllPayment);
    } else {
      if (this.isValidateScan) this.isErrorValidationScan = true;
      else this.isErrorTyping = true;
      this.errorSttNumber = detailSTT.errorMessage;
      playNotification("error");
    }
  }
  onStiDestScData(detailSTT: any, checkAllPayment: any) {
    const indexCurrentSttNumber = this.dataSttToBeUpdate.findIndex(
      (data: any) => {
        return data.sttNumber === detailSTT.sttNumber;
      }
    );
    if (indexCurrentSttNumber > -1) {
      playNotification("error");
      this.errorSttNumber = "No. STT sudah di input";
      if (this.isValidateScan) this.isErrorValidationScan = true;
      else this.isErrorTyping = true;
      return;
    } else {
      this.isPaid = detailSTT.isPaid;
      if (this.isPaid && checkAllPayment) {
        // show status stt success
        this.onCheckStatus(detailSTT);
        this.onSuccessStiDestSc(detailSTT);
        playNotification("success");
      } else {
        this.isUnpaidNotification = !detailSTT.isPaid;
        playNotification("error");
      }
    }
    this.detailSttNotification = {
      sttNumber: detailSTT.sttNumber,
      sttElexysNumber: detailSTT.sttElexysNumber,
      totalPieces: detailSTT.piece.length,
      ...detailSTT.piece[0]
    };

    // reset form
    this.form.sttNumber = "";
    if (!this.isErrorValidationScan) {
      this.isValidateScan = false;
    }
    this.notification = true;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();

    if (this.isPaid) {
      // remove notification after 15 second
      setTimeout(() => {
        this.notification = false;
        this.isStiDest = false;
        this.isMisRoute = false;
        this.isTransit = false;
      }, 15000);
    }
  }
  onCheckStatus(detailSTT: any) {
    if (detailSTT.status.toLowerCase() === "transit") this.isTransit = true;
    else if (detailSTT.status.toLowerCase() === "mis-route")
      this.isMisRoute = true;
    else this.isStiDest = true;
  }
  onSuccessStiDestSc(detailSTT: any) {
    this.dataSttToBeUpdate.push(
      new STTToBeGenerate(
        detailSTT.sttNumber,
        detailSTT.bagNumber,
        detailSTT.piece.map((e: any) => ({
          ...e,
          isTransit: this.isTransit ? "Ya" : "Tidak",
          isMisRoute: this.isMisRoute ? "Ya" : "Tidak",
          isStiDest: !this.isTransit && !this.isMisRoute ? "Ya" : "Tidak",
          bagNumber: detailSTT.bagNumber,
          sttNumber: detailSTT.sttNumber
        })),
        detailSTT.product,
        detailSTT.commodity,
        detailSTT.totalPieces,
        detailSTT.grossWeight,
        detailSTT.volumeWeight,
        detailSTT.origin,
        detailSTT.destination,
        this.isTransit ? "Ya" : "Tidak",
        this.isMisRoute ? "Ya" : "Tidak",
        !this.isTransit && !this.isMisRoute ? "Ya" : "Tidak",
        "",
        detailSTT.cargoNumber,
        this.isSttNumber(this.form.sttNumber)
          ? "-"
          : this.isLiloBag(this.form.sttNumber)
          ? "-"
          : this.form.sttNumber
      )
    );
  }
  get totalStt() {
    return this.dataSttToBeUpdate.length;
  }
  get calculatedStt() {
    return this.dataSttToBeUpdate.reduce(
      (a: any, b: any) => {
        return {
          totalPieces: a.totalPieces + b.sttPieces.length,
          totalGrossWeight:
            a.totalGrossWeight +
            b.sttPieces.reduce((c: any, d: any) => {
              return c + d.grossWeight;
            }, 0),
          totalVolumeWeight:
            a.totalVolumeWeight +
            b.sttPieces.reduce((c: any, d: any) => {
              return c + d.volumeWeight;
            }, 0)
        };
      },
      {
        totalPieces: 0,
        totalGrossWeight: 0,
        totalVolumeWeight: 0
      }
    );
  }
  get totalPieces() {
    return this.calculatedStt.totalPieces;
  }
  get totalGrossWeight() {
    return this.calculatedStt.totalGrossWeight;
  }
  get totalVolumeWeight() {
    return this.calculatedStt.totalVolumeWeight;
  }

  // STI DEST-SC
  get totalBagVolumeWeight() {
    const total =
      (this.form.length * this.form.width * this.form.height) / 6000;
    return total % 1 === 0 ? total : total.toFixed(1);
  }

  // alert popup
  isErrorTyping = false;
  isErrorValidationScan = false;
  isPartial = false;
  get sttFailedUpdate() {
    return StiDestScController.sttFailed;
  }
  setPartial(value: boolean) {
    this.isPartial = value;
  }
  onResetIsAlreadyExist() {
    this.form.sttNumber = "";
    this.errorSttNumber = "";
    this.isErrorTyping = false;
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();
  }
  get alertPopup() {
    // if success
    if (this.openSuccess)
      return {
        onClick: () => this.onCloseSuccess(),
        title: "Perubahan Status STT Berhasil !",
        message: "Status STT berhasil diupdate menjadi STI DEST-SC.",
        image: "image-modal-success"
      };
    // if error typing not found
    if (this.isErrorTyping)
      return {
        onClick: () => this.onResetIsAlreadyExist(),
        title: "Tambah No. STT Gagal",
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if input by scan
    else if (this.isErrorValidationScan)
      return {
        onClick: () => this.onResetForm(),
        title: "Scan No. STT Gagal",
        message: this.errorSttNumber,
        image: "image-modal-failed"
      };
    // if success partial
    if (this.isPartial)
      return {
        onClick: () => this.onCloseSuccess(),
        onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
        title: "Perubahan Status Berhasil !",
        message:
          "Beberapa status STT berhasil diupdate menjadi STI DEST-SC, namun terdapat kegagalan pada beberapa STT. Silahkan download untuk mengetahui kesalahan.",
        image: "image-modal-warning"
      };
    // default is failed
    return {
      onClick: () => this.setFailed(false),
      onDownload: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
      title: "Perubahan Status Gagal !",
      message: "Perubahan status STI DEST-SC gagal dibuat.",
      image: "image-modal-failed"
    };
  }
  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "sti_dest_sc_stt_failed.csv",
      listStt: data
    });
  }

  // form model
  form = {
    date: new Date(),
    sttNumber: "",
    removeSttNumber: "",
    weight: 1,
    length: 30,
    width: 20,
    height: 10
  };

  get isDisableAddSttNumber() {
    return !this.form.sttNumber || this.totalStt >= 500;
  }

  // Origin Transit City
  get arrivalCity() {
    return `${this.dataProfile.account_location.city_code} - ${this.dataProfile.account_location.city_name}`;
  }

  // convert value decimal
  isValueDecimal(value: number): number | string {
    return convertDecimal(value);
  }

  // format total kg
  convertTotalKg(value: number) {
    return convertDecimalAfterComma(value, 1);
  }

  // panel pickup
  togglePanel(event: any) {
    const refs: any = this.$refs;
    refs.op.toggle(event);
  }

  // notification handler
  notification = false;
  detailSttNotification = null;

  // table
  pagination = {
    page: 1,
    limit: 50
  };

  sourceSttFromLocalStorage: any = JSON.parse(
    localStorage.getItem("sti-dest-sc") || "[]"
  );

  get dataSttToBeUpdate() {
    storeDatatoLocalStorage("sti-dest-sc", this.sourceSttFromLocalStorage);
    return this.sourceSttFromLocalStorage;
  }

  get columns() {
    const tmp = [
      {
        name: "No.",
        styleHead: "w-10 text-center whitespace-no-wrap",
        styleCustom: "align-top",
        render: (_: STTToBeGenerate, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Bag",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${item.bagNumber}</div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${item.sttNumber}</div>`;
        }
      },
      {
        name: "No. Referensi",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${item.sttNoRef}</div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.product}
                  </div>
                </div>`;
        }
      },
      {
        name: "Komoditas",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${item.commodity}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-24 text-left whitespace-no-wrap",
        styleCustom: "justify-center",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${item.totalPieces}</div>`;
        }
      },
      {
        name: "Berat Kotor",
        styleHead: "w-36 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.grossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Berat Dimensi",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${convertDecimalWithComma(
            item.volumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Dest.",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.destination}
                  </div>
                </div>`;
        }
      },
      {
        name: "Transit",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${item.isTransit}</div>`;
        }
      },
      {
        name: "Misroute",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: STTToBeGenerate) => {
          return `<div class="text-black-lp-300">${item.isMisroute}</div>`;
        }
      }
    ];

    !isMigrateFromElexys() && tmp.splice(2, 1);
    return tmp;
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  withPrint = false;
  countSuccess = 0;
  countFailed = 0;
  async onSave() {
    this.handleConfirmationSave(false);
    const resp = await StiDestScController.onGenerateStiDestSc({
      sttNumber: this.dataSttToBeUpdate
    });
    if (resp.data) {
      if (resp.data.total_stt_failed > 0 && resp.data.total_stt_success === 0) {
        this.countFailed = resp.data.total_stt_failed;
        this.setFailed(true);
        StiDestScController.setSttFailed(resp.data.stt_failed);
      } else if (
        resp.data.total_stt_failed > 0 &&
        resp.data.total_stt_success > 0
      ) {
        this.countFailed = resp.data.total_stt_failed;
        this.countSuccess = resp.data.total_stt_success;
        this.isPartial = true;
        StiDestScController.setSttFailed(resp.data.stt_failed);
      } else {
        StiDestScController.setOpenSuccess(true);
        if (this.withPrint) {
          const refs: any = this.$refs;
          refs.print.print({
            stiDestScId: StiDestScController.stiDestScIdToGeneratePdf
          });
        }
      }
      this.sourceSttFromLocalStorage = [];
    }
    this.handleConfirmationSave(false);
  }

  createAndPrint(withPrint: boolean) {
    const refs: any = this.$refs;
    refs.op.hide();
    this.withPrint = withPrint;
    this.handleConfirmationSave(true);
  }

  // handle route leave and modal
  // navigation
  get openSuccess(): boolean {
    return StiDestScController.isOpenSuccess;
  }
  setOpenSuccess(value: boolean) {
    StiDestScController.setOpenSuccess(value);
  }

  // failed for create
  get isFailed(): boolean {
    return StiDestScController.isFailed;
  }
  setFailed(value: boolean) {
    StiDestScController.setFailed(value);
  }

  // error for api
  get errorType() {
    return StiDestScController.errorCause;
  }
  get isError() {
    return StiDestScController.isError;
  }
  handleCloseError() {
    StiDestScController.setError(false);
  }

  openConfirmationLeave = false;
  openConfirmationSave = false;
  answer = false;
  newPath = "";
  handleConfirmationLeave(value: boolean) {
    this.openConfirmationLeave = value;
  }
  handleConfirmationSave(value: boolean) {
    this.openConfirmationSave = value;
  }
  goBack() {
    this.$router.push("/sti-dest-sc");
  }
  onOpenClosePage(to: any) {
    this.handleConfirmationLeave(true);
    this.newPath = to.path;
  }
  handlerClose() {
    removeDataLocalStorage("sti-dest-sc");
    this.handleConfirmationLeave(false);
    this.answer = true;
    this.$router.push(this.newPath);
  }
  handlerCancelClose() {
    this.answer = false;
    this.handleConfirmationLeave(false);
  }
  onCloseSuccess() {
    this.goBack();
  }
}
